import React from "react";
import { renderToString } from "react-dom/server";

import { Theme } from "../components/theme";
import BlockEditorContent from "./react";
import sanitise from "./sanitise-html";
import { validateRichTextDocumentOrThrow } from "./validate";

export const THEME: Theme = {
  styles: {
    ChartContainer: "insight_body__chart",
  },
  stringFallback: true,
};

export function validatedHtmlOrLexical(data: string) {
  if (data[0] === "{") return validateRichTextDocumentOrThrow(data);
  return sanitise(data);
}

/** Returns the HTML as a string, otherwise renders valid HTML string as inline lexical  */
export function renderedHtmlOrLexical(document: string) {
  if (!document) return "";
  const validated = validatedHtmlOrLexical(document);
  if (typeof validated === "string") return validated;
  return renderToString(<BlockEditorContent content={validated} theme={THEME} />);
}
